import { graphql } from 'gatsby';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'gatsby-plugin-react-i18next';

// Components & UI
import ProfileLayout from 'components/layouts/profile/profile.layout';

const FamilyAccount: React.FC = () => {
    const { t } = useTranslation();
    return (
        <>
            <ProfileLayout
                eyebrowText={t(`pages.profile.eyebrowText`)}
                title={t(`pages.profile.familyAccount.title`)}
                heading={t(`pages.profile.familyAccount.familySection.heading`)}
                sections={[
                    {
                        heading: t(`pages.profile.familyAccount.familySection.heading`),
                        children: FamilyAccountSection()
                    },
                    {
                        heading: t(`pages.profile.familyAccount.myCaregiversSection.heading`),
                        children: MyCaregiversSection()
                    }
                ]}
            />
        </>
    );
};

export default FamilyAccount;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
const FamilyAccountSection = () => {
    const { t } = useTranslation();
    return (
        <Container fluid>
            <Row>
                <Col>
                    <p className="profile-empty-message">
                        {t(`pages.profile.familyAccount.familySection.emptyMessage`)}
                    </p>
                </Col>
            </Row>
        </Container>
    );
};
const MyCaregiversSection = () => {
    const { t } = useTranslation();
    return (
        <Container fluid>
            <Row>
                <Col>
                    <p className="profile-empty-message">
                        {t(`pages.profile.familyAccount.myCaregiversSection.emptyMessage`)}
                    </p>
                </Col>
            </Row>
        </Container>
    );
};
